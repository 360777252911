
















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  readAdminDocumentsUsuarios,
  readAdminDocuments,
} from '@/store/admin/getters';
import {
  dispatchDeleteDocumento,
  dispatchGetDocumentos,
  dispatchAsignarDocumentos,
} from '@/store/admin/actions';
import { apiUploadFolder } from '@/env';
import { IUUsersDocuments } from '@/interfaces';
import { api } from '@/api';
import { getLocalToken } from '@/utils';



@Component
export default class AdminDocumentos extends Vue {

  public documentosselect = [] as any;
  public searchDocuments = '';
  public isLoadingDocuments = false;
  public alertMsg = false;
  public expanded = [];
  public idDocumentos = [] as any;
  public selectedUser = { id: '', nombre: '' };
  public searchTxt = '';
  public allDocuments = [] as any;
  public editedItem = {
    id_usuario: '',
    id_documentos: [],
    usuario: '',
  };

  public headers = [
    {
      text: 'Nombre',
      sortable: true,
      value: 'nombre',
      align: 'left',
    },
    {
      text: 'Cod.Inquilino',
      sortable: true,
      value: 'codigo_inquilino',
      align: 'left',
    },
    {
      text: 'Cod.Propietario',
      sortable: true,
      value: 'codigo_propietario',
      align: 'left',
    },
    {
      text: 'Email',
      sortable: true,
      value: 'email',
      align: 'left',
    },
    {
      text: 'Documentos',
      sortable: true,
      value: 'n_documento',
      align: 'left',
    },
    {
      text: 'Asignar',
      sortable: true,
      value: 'asignar',
      align: 'left',
    },
  ];
  @Watch('searchDocuments')
  public async onChildChanged(val: string, oldVal: string) {

    // Items have already been loaded
    if (this.documentosselect.length > 0) { return; }

    // Items have already been requested
    if (this.isLoadingDocuments) { return; }

    this.isLoadingDocuments = true;
    const token = getLocalToken()!;
    const response = await api.getDocumentsBuscar(
      token, ''
    );
    const i = parseInt(this.selectedUser.id);
    const responseUserDocument = await api.getDocumentsUserBuscar(
      token, i
    );
    this.documentosselect = response.data;
    const documentos=responseUserDocument.data;



    let ids = [] as any;

    ids = documentos.documentos.map((documentos) => documentos.id);

    this.idDocumentos = ids;

    this.isLoadingDocuments = false;
  }
  public async buscarBtn() {
    this.allDocuments = [];
    this.isLoadingDocuments = true;
    const token = getLocalToken()!;
    const response = await api.getDocumentsUsuariosBuscar(
      token, this.searchTxt
    );
    this.isLoadingDocuments = false;
    this.allDocuments = response.data;
    // console.log(response);
    //   let alldocs = [] as any;
    //   alldocs = this.documentos;
    //   const filtro = alldocs.filter((doc) => {
    //     return (
    //       this.stripVowelAccent(doc.nombre.toLowerCase()).indexOf(
    //         this.stripVowelAccent(this.searchTxt.toLowerCase()),
    //       ) !== -1 ||
    //       this.stripVowelAccent(doc.descripcion.toLowerCase()).indexOf(
    //         this.stripVowelAccent(this.searchTxt.toLowerCase()),
    //       ) !== -1
    //     );
    //   });
    //   this.allDocuments = filtro;
    // } else {
    //   this.allDocuments = this.documentos;
    // }
  }
public async borrarDocumento(id) {

    await dispatchDeleteDocumento(this.$store, id);
    this.alertMsg = false;
    await this.buscarBtn();
  }

  public url(u) {
    return apiUploadFolder + u;
  }
  // get documentosselect() {
  //   const docs = readAdminDocuments(this.$store);
  //   return docs;
  // }
  // get documentos() {
  //   const docs1 = readAdminDocumentsUsuarios(this.$store);
  //   return docs1;
  // }
  public async iniciar() {
    //await dispatchGetDocumentosUsuarios(this.$store);
    // await dispatchGetDocumentos(this.$store);
    // this.allDocuments = this.documentos;
  }
  public async mounted() {

    await this.iniciar();
  }
  public close() {
    this.alertMsg = false;
  }
  public async save() {
    const documentos: IUUsersDocuments = {
      user_id: Number(this.selectedUser.id),
      id_documentos: this.idDocumentos,
    };
    await dispatchAsignarDocumentos(this.$store, documentos);
    await this.iniciar();
    await this.buscarBtn();
    this.alertMsg = false;
  }
  public showAlert(i) {
    this.selectedUser = i;
    // const documentosuser = readAdminDocumentsUsuarios(this.$store).filter(
    //   (user) => user.id === i.id,
    // );
    // let ids = [] as any;

    // ids = documentosuser[0].documentos.map((documentos) => documentos.id);

    // this.idDocumentos = ids;

    this.alertMsg = true;
  }
  // public buscar() {
  //   if (this.searchTxt.length > 0) {
  //     let alldocs = [] as any;
  //     alldocs = this.documentos;
  //     const filtro = alldocs.filter((doc) => {
  //       return (
  //         this.stripVowelAccent(doc.nombre.toLowerCase()).indexOf(
  //           this.stripVowelAccent(this.searchTxt.toLowerCase()),
  //         ) !== -1 ||
  //         this.stripVowelAccent(doc.email.toLowerCase()).indexOf(
  //           this.stripVowelAccent(this.searchTxt.toLowerCase()),
  //         ) !== -1 ||
  //         this.stripVowelAccent(doc.codigo_propietario?.toLowerCase()).indexOf(
  //           this.stripVowelAccent(this.searchTxt.toLowerCase()),
  //         ) !== -1 ||
  //         this.stripVowelAccent(doc.codigo_inquilino?.toLowerCase()).indexOf(
  //           this.stripVowelAccent(this.searchTxt.toLowerCase()),
  //         ) !== -1
  //       );
  //     });
  //     this.allDocuments = filtro;
  //   } else {
  //     this.allDocuments = this.documentos;
  //   }
  // }
  public stripVowelAccent(str) {
    const rExps = [
      { re: /[\xC0-\xC6]/g, ch: 'A' },
      { re: /[\xE0-\xE6]/g, ch: 'a' },
      { re: /[\xC8-\xCB]/g, ch: 'E' },
      { re: /[\xE8-\xEB]/g, ch: 'e' },
      { re: /[\xCC-\xCF]/g, ch: 'I' },
      { re: /[\xEC-\xEF]/g, ch: 'i' },
      { re: /[\xD2-\xD6]/g, ch: 'O' },
      { re: /[\xF2-\xF6]/g, ch: 'o' },
      { re: /[\xD9-\xDC]/g, ch: 'U' },
      { re: /[\xF9-\xFC]/g, ch: 'u' },
      { re: /[\xD1]/g, ch: 'N' },
      { re: /[\xF1]/g, ch: 'n' },
    ];
    if (str) {
      for (let i = 0, len = rExps.length; i < len; i++) {
        str = str.replace(rExps[i].re, rExps[i].ch);
      }
    } else {
      return '';
    }
    return str;
  }
}
