var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"light":""}},[_c('v-toolbar-title',[_vm._v(" Gestión de Documentos de los usuarios ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"hide-details":"","single-line":"","clearable":"","placeholder":"Buscar por nombre usuario o código"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.buscarBtn()}},model:{value:(_vm.searchTxt),callback:function ($$v) {_vm.searchTxt=$$v},expression:"searchTxt"}}),_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.buscarBtn()}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1),_c('v-spacer')],1),(_vm.allDocuments.length >= 1)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.allDocuments,"item-key":"id","show-expand":"","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.n_documento",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(item.documentos.length)+" ")])]}},{key:"item.asignar",fn:function(ref){
var on = ref.on;
var item = ref.item;
return [_c('v-btn',_vm._g({attrs:{"text":""},on:{"click":function($event){return _vm.showAlert(item)}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":"5"}},[_c('table',_vm._l((item.documentos),function(i){return _c('tr',{key:i.id},[_c('td',{attrs:{"width":"1%"}},[_c('v-btn',{attrs:{"color":"primary","depressed":"","icon":"","href":_vm.url(i.url),"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-file-"+_vm._s(i.tipo.tipo_ico))])],1)],1),_c('td',[_vm._v(_vm._s(i.nombre))]),_c('td',{attrs:{"width":"10%"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""},on:{"click":function($event){return _vm.borrarDocumento(i.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("¿Borrar documento?")])])],1)])}),0)])]}}],null,false,687778768)}):_vm._e(),(_vm.isLoadingDocuments)?_c('div',[_c('v-progress-linear',{attrs:{"color":"red accent-4","indeterminate":"","rounded":"","height":"6"}}),_c('p',{staticClass:"mt-5 mb-5 ml-5"},[_vm._v("Por favor espere, este proceso puede tardar unos minutos...")])],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.alertMsg),callback:function ($$v) {_vm.alertMsg=$$v},expression:"alertMsg"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Documentos de: "+_vm._s(_vm.selectedUser.email)+" ")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"24"}},[_c('v-autocomplete',{attrs:{"items":_vm.documentosselect,"item-value":"id","item-text":"nombre","loading":_vm.isLoadingDocuments,"search-input":_vm.searchDocuments,"outlined":"","dense":"","chips":"","small-chips":"","label":"Selecciona los documentos del usuario","multiple":""},on:{"update:searchInput":function($event){_vm.searchDocuments=$event},"update:search-input":function($event){_vm.searchDocuments=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","small":"","color":"primary"}},[_vm._v(_vm._s(item.nombre))])]}}]),model:{value:(_vm.idDocumentos),callback:function ($$v) {_vm.idDocumentos=$$v},expression:"idDocumentos"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Guardar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }